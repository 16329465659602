<div class="booking-way directionContent">
    <p class="text-center">{{translate.data.HomePage.bookingWay}}</p>
    <h2 class="text-center">{{translate.data.HomePage.bookigDescription}}</h2>
    <div class="container" style="max-width: 750px;">
        <div class="row">
            <div class="col-12 col-md-4" *ngFor="let reverseStep of bookingSteps">
                <div class="chooce-place text-center">
                    <div class="img">
                        <img src="{{sharedService.ipRootImg + reverseStep.photo}}" loading="lazy"> 
                    </div>
                    <p>{{reverseStep[translate.data.requestdData.genTite]}}</p>
                    <span>{{reverseStep[translate.data.requestdData.genDescription]}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
