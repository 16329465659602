import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { TranslationService } from 'src/app/shared/translation.service';
import { PagesService } from '../../pages.service';

@Component({
  selector: 'app-best-service',
  templateUrl: './best-service.component.html',
  styleUrls: ['./best-service.component.scss']
})
export class BestServiceComponent implements OnInit {

  constructor(public translate: TranslationService,
    public sharedService: SharedService,
    private pagesService: PagesService) { }

  services: any[];

  ngOnInit(): void {
    console.log(this.translate.data.HomePage)
    this.pagesService.getServices().subscribe(
      (response: any) => {
        console.log(response);
        this.services = response.data;
        console.log('best servicesss ===> ', this.services);
      },
      (error) => {
        console.log(error);
      }
    )
  }

}
