import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslationService } from 'src/app/shared/translation.service';
import { PagesService } from '../../pages.service';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit {

  offersList: any[];

  constructor(public translate: TranslationService,
     private pagesService: PagesService,
      private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    //this.spinner.show();

    this.pagesService.getAllKaravansOffers().subscribe(
      (response: any) => {
        this.offersList = response.data.offersLst;
        //this.pagesService.karavans.next(response.data.offersLst.offerCaravans);
        this.pagesService.karavans.next([{id: 2, name: 'ahmmmmmed'}]);
        setTimeout(() =>{
          this.spinner.hide();
        }, 3000);
      },
      (error) => {
        console.log(error);
      }
    );

  }

}
