import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { HomeComponent } from './home.component';
import { HomeRoutingModule } from './home-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SharedService } from 'src/app/shared/shared.service';
import { SliderComponent } from './slider/slider.component';
import { TestemonialComponent } from './testemonial/testemonial.component';
import { BookingWayComponent } from './booking-way/booking-way.component';
import { BestServiceComponent } from './best-service/best-service.component';
import { OffersComponent } from './offers/offers.component';
import { PagesSharedModule } from '../pagesShared.module';




@NgModule({
  declarations: [
    HomeComponent,
    SliderComponent,
    TestemonialComponent,
    BookingWayComponent,
    BestServiceComponent,
    OffersComponent,

  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    SharedModule,
    PagesSharedModule,
  ],

  providers: [
    SharedService,
    DatePipe
  ]
})
export class HomeModule { }
