import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from 'src/app/shared/shared.service';
import { TranslationService } from '../../../shared/translation.service';
import { PagesService } from '../../pages.service';
import { Carousel } from 'primeng/carousel';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  sliderData: any;

  constructor(public translate: TranslationService,
    public sharedService: SharedService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private pagesService: PagesService) {
      Carousel.prototype.onTouchMove = () => { };
    }

  ngOnInit(): void {
    this.spinner.show();
    //get slider data
    this.pagesService.getSlider().subscribe(
      (response: any) => {
        this.sliderData = response.data;
        console.log('slider data ===> ', this.sliderData);
        setTimeout(() => {
          this.spinner.hide();
        }, 3000);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  //handel reservation
  handelReservation() {
    this.router.navigate(['../search'], {relativeTo: this.route, queryParams: {data: JSON.stringify({}) , pageNo:1}})
  }

}
