<app-slider></app-slider>
<app-filtter></app-filtter>
<app-testemonial></app-testemonial>
<app-booking-way></app-booking-way>
<app-best-service></app-best-service>
<!-- <app-offers></app-offers> -->
<app-our-clients></app-our-clients>

<router-outlet></router-outlet>

