import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { TranslationService } from 'src/app/shared/translation.service';
import { PagesService } from '../../pages.service';

@Component({
  selector: 'app-booking-way',
  templateUrl: './booking-way.component.html',
  styleUrls: ['./booking-way.component.scss']
})
export class BookingWayComponent implements OnInit {

  constructor(public translate: TranslationService,
    public sharedService: SharedService,
    private pagesService: PagesService) { }

  bookingSteps: any[];

  ngOnInit(): void {
    this.pagesService.getReservationSteps().subscribe(
      (response: any) => {
        this.bookingSteps = response.data;
        console.log('booking Steeeps => ', this.bookingSteps);
      },
      (error) => {
        console.log(error);
      }
    )
  }

}
