<div class="best-service">
    <div class="col-12 col-lg-4 relativeC d-none d-lg-block">
        <div class="img">
            <img src="../../../../assets/features.png" alt="" title=""/>
        </div>
    </div>
    <div class="col-12 col-lg-8">
        <div [ngClass]="{'bestSec-info': translate.is_arabic, 'bestSec-info-en': !translate.is_arabic}">
            <p>{{translate.data.HomePage.bestService}}</p>
            <h2>{{translate.data.HomePage.bestServiceHeading}}</h2>
            <ul class="list-unstyled">
                <li *ngFor="let service of services">
                    <div class="icon">
                        <img src="{{sharedService.ipRootImg + service.photo}}" loading="lazy"/>
                    </div>
                    <div class="info">
                        <h3>{{service[translate.data.requestdData.genTite]}}</h3>
                        <span>{{service[translate.data.requestdData.genDescription]}}</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
