<div *ngIf="brandsImgs?.length" class="testimonial">
    <div class="container">
      <!-- <h2 class="text-center" *ngIf="!brandsImgs?.length">لا يوجد بيانات لعرضها </h2> -->
        <div class="iamges">
            <p-carousel
                        [style]="{'width': '70vw'}"
                        [value]="brandsImgs"
                        [autoplayInterval]="3000"
                        [numScroll]="1" [numVisible]="4"
                        [circular]="true"
                        >
                <ng-template let-car pTemplate="item">
                    <div class="conteiner">
                        <div class="img">
                            <img src="{{sharedService.ipRootImg + car.image}}" loading="lazy"/>
                        </div>
                    </div>
                </ng-template>
            </p-carousel>
        </div>
    </div>
</div>
