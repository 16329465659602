<div class="offers directionContent text-center">
    <h1>{{translate.data.offers.title}}</h1>
    <p>{{translate.data.offers.underTitle}}</p>
    <div class="container">
        <div class="tabsSlide">
            <p-tabView>
               <!-- <p-tabPanel *ngFor="let data of offersList" header={{data.nameAr}}>
                    <app-karavan></app-karavan>
                </p-tabPanel>-->
                <p-tabPanel header="Header 1">
                  Content 1
                </p-tabPanel>
                <p-tabPanel header="Header 2">
                    Content 2
                </p-tabPanel>
                <p-tabPanel header="Header 3">
                    Content 3
                </p-tabPanel>
                <p-tabPanel header="Header 4">
                    Content 4
                </p-tabPanel>
                <p-tabPanel header="Header 5">
                    Content 5
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
</div>
