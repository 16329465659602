import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FiltterComponent } from './../components/filtter/filtter.component';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  
/*   @ViewChild(FiltterComponent) child!:FiltterComponent;
  res:any; */

  constructor() {
   }

  ngOnInit(): void {
  }


}
