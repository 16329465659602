import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { PagesService } from '../../pages.service';

@Component({
  selector: 'app-testemonial',
  templateUrl: './testemonial.component.html',
  styleUrls: ['./testemonial.component.scss']
})
export class TestemonialComponent implements OnInit {

  constructor(private pagesService: PagesService,
     public sharedService: SharedService) {
     }

  brandsImgs: any[]; //images brands array


  ngOnInit(): void {
    this.pagesService.getBrands().subscribe(
      (response: any) => {
        this.brandsImgs = response.data;
        console.log('brands img => ', this.brandsImgs);
      },
      (error) => {
        console.log(error);
      }
    )
  }

}
