<div class="slider">
    <div class="container">
        <p-carousel [value]="sliderData" [numVisible]="1" [autoplayInterval]="5000" [numScroll]="2" [circular]="true">
            <ng-template let-item pTemplate="item">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="slider-img">
                            <img src="{{ sharedService.ipRootImg + item.imagePath}}" loading="lazy" title="slider" alt="slider"/>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div [ngClass]="{'slider-content': translate.is_arabic, 'slider-contentEn': !translate.is_arabic}">
                            <h2>{{item[translate.data.requestdData.genAdress]}}</h2>
                            <p [ngClass]="{'underLine': translate.is_arabic, 'underLineEn': !translate.is_arabic}"></p>
                            <p>
                                {{item[translate.data.requestdData.genDescription]}}
                            </p>
                            <button (click)="handelReservation()">{{translate.data.result.bookNow}}</button>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-carousel>
    </div>
</div>